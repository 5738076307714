body {
  background-color: #F6F8FA;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color-old: #282c34;
  background-color: #ae0000;
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  #align-items: center;
  justify-content: left;
  font-size: calc(12px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

li {
  display: inline-block;
  width: 20%;
  margin: 0px;
}
li.OK {
  background-color : green;
}
li.NOT-OK {
   background-color : red;
 }

ul {
  padding-inline-start: 0px;
}

ul.nobull {
  list-style-type: none;
  width : 100%;
  margin-top : 20px;
  padding : 0px;
}


.App-main {
  min-height: 75vh;
}

.App-footer {
  height: 10vh;
  background-color: #282c34;
}

#root {
  width: 100vw
}

.header-logo__image {
  max-width: 150px;
  margin-left: 20px;
  margin-top: 20px;
}